import React, { FunctionComponent } from 'react';
import { useController, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { FormControl, FormLabel, makeStyles, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { S } from '@mobily/ts-belt';
import { TT_LINK_DEFAULT_PORT, TTLinkEndpointSchema } from '@netinsight/crds-timetransfer';
import {
  CheckboxField,
  TextField,
  isNullableOrEmpty,
  useTextFieldController,
  RadioSingle,
  useRadioSingleController,
  useFormStyles,
} from '@netinsight/management-app-common-react';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { LinkEndpointNodeSelector } from './LinkEndpointNodeSelector';
import { useInterfaceInfos, useInterfaceUsage } from '@netinsight/plugin-node-manager-ui';
import { LinkEndpointInterfaceSelector } from './LinkEndpointInterfaceSelector';

const useStyles = makeStyles((theme: Theme) => ({
  endpointContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(2),
    borderColor: theme.palette.grey[50],
    ['& > legend']: {
      marginBottom: theme.spacing(2),
    },
  },
  channelSummary: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
}));

export const LinkEndpoint: FunctionComponent<{
  prefix: 'endpointA' | 'endpointB';
  label: string;
}> = ({ label, prefix }) => {
  const styles = useStyles();
  const formStyles = useFormStyles();
  const { control } = useFormContext<PersistedSyncLink>();
  const { errors, defaultValues } = useFormState({ control });
  const linkId = useWatch({ control, name: 'id' });
  const currentPortPlaceHolder = linkId ? (defaultValues?.[prefix]?.port ?? TT_LINK_DEFAULT_PORT).toString() : 'Auto';

  const { field: tcpServerFieldProps } = useRadioSingleController({
    control,
    name: 'tcpServer',
  });

  const selectableFieldProps = useController({
    control,
    name: `${prefix}.selectable`,
  });

  const { field: portInputProps } = useTextFieldController({
    control,
    name: `${prefix}.port`,
    label: 'UDP port',
    schema: TTLinkEndpointSchema.shape.port,
    placeholder: currentPortPlaceHolder,
  });

  const { field: dscpInputProps } = useTextFieldController({
    control,
    name: `${prefix}.dscp`,
    label: <abbr title="Differentiated Services Code Point">DSCP</abbr>,
    schema: TTLinkEndpointSchema.shape.dscp,
    placeholder: '46',
  });

  const { field: tcpDscpInputProps } = useTextFieldController({
    control,
    name: `${prefix}.tcpDscp`,
    label: <abbr title="Differentiated Services Code Point">DSCP</abbr>,
    schema: TTLinkEndpointSchema.shape.tcpDscp,
    placeholder: '46',
  });
  const nodeId = useWatch({ control, name: `${prefix}.node` });

  const { data: interfaceUsage, isLoading: isLoadingInterfaceUsage } = useInterfaceUsage({ nodeId });
  const { data: availableInterfaces, isLoading: isLoadingAllInterfaces } = useInterfaceInfos({
    nodeId,
    statuses: [],
    includeVlan: true,
    includeManagement: true,
    usageMap: interfaceUsage,
  });
  const isLoadingInterfaces = isLoadingInterfaceUsage || isLoadingAllInterfaces;

  return (
    <FormControl component="fieldset" className={styles.endpointContainer} data-testid={`fieldset-${prefix}`}>
      <FormLabel component="legend" style={{ fontWeight: 'bold' }}>
        {label}
      </FormLabel>
      {S.isNotEmpty(errors[prefix]?.message ?? '') && (
        <Alert color="error" icon={<ErrorIcon />} data-testid={`error-alert-${prefix}`}>
          {errors[prefix]?.message}
        </Alert>
      )}
      <CheckboxField fieldProps={selectableFieldProps} label="Selectable" />
      <LinkEndpointNodeSelector prefix={prefix} />
      {!isNullableOrEmpty(nodeId) && !isLoadingInterfaces ? (
        <>
          <LinkEndpointInterfaceSelector prefix={prefix} availableInterfaces={availableInterfaces} />
          <details open>
            <summary className={styles.channelSummary}>UDP time channel</summary>
            <div className={formStyles.formGrid}>
              <TextField {...portInputProps} fullWidth />
              <TextField
                {...dscpInputProps}
                fullWidth
                type="text"
                placeholder="EF (46)"
                inputProps={{ list: 'dscp-code-points' }}
              />
            </div>
          </details>
          <details>
            <summary className={styles.channelSummary}>TCP utility channel</summary>
            <div className={formStyles.formGrid}>
              <RadioSingle
                value={prefix}
                label="TCP server"
                description="A time transfer link sets up a TCP connection for control traffic. This setting controls which of the end points is the server and which is the client. As long as the end points can reach each other, it does not matter which end point is the server."
                {...tcpServerFieldProps}
              />
              <TextField
                {...tcpDscpInputProps}
                fullWidth
                type="text"
                placeholder="EF (46)"
                inputProps={{ list: 'dscp-code-points' }}
              />
            </div>
          </details>
        </>
      ) : null}
    </FormControl>
  );
};
