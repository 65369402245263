import React from 'react';
import { Grid } from '@material-ui/core';
import { TimeNodeConfig } from './TimeNodeConfig';
import { TimeNodeHostFingerprints } from './TimeNodeHostFingerprints';
import { TimeNodeAuthenticationConfig } from './TimeNodeAuthenticationConfig';
import { TimeNodeRemoval } from './TimeNodeRemoval';
import { TimeNodeReplace } from './TimeNodeReplace';
import { TimeNodeReboot } from './TimeNodeReboot';

export const TimeNodeConfigurationTab = ({ nodeId }: { nodeId: string }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TimeNodeConfig nodeId={nodeId} />
      </Grid>
      <Grid item xs={12}>
        <TimeNodeHostFingerprints nodeId={nodeId} />
      </Grid>
      <Grid item xs={12}>
        <TimeNodeAuthenticationConfig nodeId={nodeId} />
      </Grid>
      <Grid item xs={12}>
        <TimeNodeReboot nodeId={nodeId} />
      </Grid>
      <Grid item xs={12}>
        <TimeNodeReplace nodeId={nodeId} />
      </Grid>
      <Grid item xs={12}>
        <TimeNodeRemoval nodeId={nodeId} />
      </Grid>
    </Grid>
  );
};
