import { TimeTransferSpecSchema } from '@netinsight/crds-timetransfer';
import {
  TimeNodeSpecSchema,
  TimeNodeStatusSchema,
  TimeNodeManifestStatusSchema,
  TimeNodeManifestPodSchema,
  TimeNodeLifecycleStateSchema,
  TimeNodeManifestNodeStatusCondition,
} from '@netinsight/crds';
import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import type { UnionToTuple, ValueOf } from 'type-fest';
import z from 'zod';
import { getPaginatedQueryResultSchema, WrappedErrorSchema } from './types';
import { GLOBAL_SSH_CONFIG_KEY, GLOBAL_SSH_DEFAULT_CONFIG, GlobalSSHConfig } from './config';
import { V1ConfigMap } from '@kubernetes/client-node';
import { CONTROL_STATES, EXTERNAL_SYNC_SOURCES } from '../constants';
import { timenodeDelete, timenodeRead, timenodeUpdate } from '@netinsight/zyntai-policy';

const NetworkInterfaceSchema = z.object({
  name: z.string(),
  ip: z.string().optional(),
  vlanId: z.number().optional(),
  networkNamespace: z.string().optional(),
});
export type NetworkInterface = z.infer<typeof NetworkInterfaceSchema>;

export const NodeNetworkSchema = z.object({
  interfaces: z.array(NetworkInterfaceSchema),
});
export type NodeNetwork = z.infer<typeof NodeNetworkSchema>;

export const ClusterNodeNetworkSchema = z.record(NodeNetworkSchema);
export type ClusterNodeNetwork = z.infer<typeof ClusterNodeNetworkSchema>;

export const ClusterTimeTransferSchema = z.record(TimeTransferSpecSchema);
export type ClusterTimeTransfer = z.infer<typeof ClusterTimeTransferSchema>;

export const TimeNodeSchema = z.object({
  id: z.string(),
  deletionTimestamp: z.string().optional(),
  spec: TimeNodeSpecSchema,
  status: TimeNodeStatusSchema.optional(),
});
export type TimeNode = z.infer<typeof TimeNodeSchema>;

export const TimeNodeManifestSchema = z.object({
  id: z.string(),
  status: TimeNodeManifestStatusSchema.optional(),
});
export type TimeNodeManifest = z.infer<typeof TimeNodeManifestSchema>;

export type NodeWorkload = z.infer<typeof TimeNodeManifestPodSchema>;

export const NodeQuerySortableColumnsSchema = z.enum([
  'name',
  'syncRegion',
  'alertsCount',
  'systemCpuTime',
  'systemMemoryUsage',
  'boardTemp',
  'controlState',
  'timeErrors.pps_in',
  'timeErrors.gnss',
  'timeErrors.ptp-recv-1',
  'timeErrors.ptp-recv-2',
]);

export type NodeQuerySortableColumns = z.infer<typeof NodeQuerySortableColumnsSchema>;

export const NodeQueryParametersSchema = z.object({
  offset: z.coerce.number().default(0).optional(),
  limit: z.coerce.number().default(25).optional(),
  orderBy: NodeQuerySortableColumnsSchema.optional(),
  orderDirection: z.enum(['asc', 'desc']).default('asc').optional(),
  nodeName: z.string().optional(),
  syncRegions: z.array(z.string()).optional(),
  controlStates: z.array(z.string()).optional(),
  lifecycleStateHeartbeatStatuses: z.array(z.string()).optional(),
  inputs: z.array(z.string()).optional(),
  outputs: z.array(z.string()).optional(),
});

export type NodeQueryParameters = z.infer<typeof NodeQueryParametersSchema>;

const HeartBeatStatusEnumSchema = TimeNodeManifestNodeStatusCondition.shape.status;
const LifecycleStateHeartbeatStatusSchema = z
  .enum([...HeartBeatStatusEnumSchema.options, ...TimeNodeLifecycleStateSchema.options])
  .exclude([TimeNodeLifecycleStateSchema.Enum.Up]);

export const TimeNodeQueryResultItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  syncRegion: z.string().optional(),
  alarmsCount: z.number().optional(),
  alarmsSeverity: z.string().optional(),
  nodeType: TimeNodeSpecSchema.shape.nodeType,
  lifecycleState: TimeNodeLifecycleStateSchema.optional(),
  heartbeatStatus: HeartBeatStatusEnumSchema.optional(),
  heartbeatTime: z.string().optional(),
  lifecycleStateHeartbeatStatus: LifecycleStateHeartbeatStatusSchema.optional(),
  inputs: z.record(z.enum(EXTERNAL_SYNC_SOURCES), z.boolean()),
  outputs: z.record(z.enum(['ptp', 'pps']), z.boolean()),
  controlState: z.enum(Object.values(CONTROL_STATES) as UnionToTuple<ValueOf<typeof CONTROL_STATES>>),
  systemCpuTime: z.number().optional(),
  systemMemoryUsage: z.number().optional(),
  boardTemp: z.number().optional(),
  timeErrors: z.record(z.enum(EXTERNAL_SYNC_SOURCES), z.number().optional()),
});

export type TimeNodeQueryResultItem = z.infer<typeof TimeNodeQueryResultItemSchema>;

const c = initContract();

export const nodeApi = c.router({
  getClusterNetwork: {
    method: 'GET',
    path: '/cluster/network',
    summary: 'Get network configuration for all nodes',
    query: z.object({
      nodeIds: z.array(z.string()).optional(),
    }),
    responses: {
      [StatusCodes.OK]: ClusterNodeNetworkSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },
  getClusterTimeTransferConfigs: {
    method: 'GET',
    path: '/cluster/timetransfer',
    summary: 'Get timetransfer configuration for all nodes',
    query: z.object({
      nodeIds: z.array(z.string()).optional(),
    }),
    responses: {
      [StatusCodes.OK]: ClusterTimeTransferSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  listNodes: {
    method: 'GET',
    path: '/nodes',
    summary: 'List all time nodes',
    responses: {
      [StatusCodes.OK]: z.object({ items: z.array(TimeNodeSchema) }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  queryNodes: {
    method: 'GET',
    path: '/nodes-query',
    summary: 'Query time nodes',
    query: NodeQueryParametersSchema,
    responses: {
      [StatusCodes.OK]: getPaginatedQueryResultSchema(TimeNodeQueryResultItemSchema),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  getNode: {
    method: 'GET',
    path: '/node/:nodeId',
    summary: 'Get time node spec and status',
    responses: {
      [StatusCodes.OK]: TimeNodeSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  listNodeManifests: {
    method: 'GET',
    path: '/node-manifests',
    summary: 'List all time node manifests',
    responses: {
      [StatusCodes.OK]: z.object({ items: z.array(TimeNodeManifestSchema) }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  getNodeStatus: {
    method: 'GET',
    path: '/nodes/:nodeId/status',
    summary: 'Get node status',
    responses: {
      [StatusCodes.OK]: TimeNodeManifestSchema.shape.status,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  getNodeNetwork: {
    method: 'GET',
    path: '/nodes/:nodeId/network',
    summary: 'Get node network configuration',
    responses: {
      [StatusCodes.OK]: NodeNetworkSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  getNodeWorkload: {
    method: 'GET',
    path: '/nodes/:nodeId/workload',
    summary: 'Get workload running on node',
    responses: {
      [StatusCodes.OK]: TimeNodeManifestPodSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeRead },
  },

  upgradeNode: {
    method: 'POST',
    path: '/nodes/:nodeId/upgrade',
    summary: 'Upgrade node',
    body: z.object({
      immediateActivation: z.boolean().default(true),
    }),
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeUpdate },
  },

  activateNode: {
    method: 'POST',
    path: '/nodes/:nodeId/activate',
    summary: 'Activate node',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeUpdate },
  },

  abortPreparedNode: {
    method: 'POST',
    path: '/nodes/:nodeId/abortprepared',
    summary: 'Abort activation of prepared node',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.object({}),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeUpdate },
  },

  rollbackNode: {
    method: 'POST',
    path: '/nodes/:nodeId/rollback',
    summary: 'Rollback node',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeUpdate },
  },

  rebootNode: {
    method: 'POST',
    path: '/nodes/:nodeId/reboot',
    summary: 'Reboot node',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeUpdate },
  },

  deleteNode: {
    method: 'DELETE',
    path: '/nodes/:nodeId',
    summary: 'Delete node',
    query: z.object({
      force: z.string().optional(),
      retainNetwork: z.string().optional(),
    }),
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: timenodeDelete },
  },
});

export function parseGlobalSSHConfig(configMap?: V1ConfigMap): GlobalSSHConfig {
  try {
    if (configMap?.data && GLOBAL_SSH_CONFIG_KEY in configMap.data) {
      return JSON.parse(configMap.data[GLOBAL_SSH_CONFIG_KEY]);
    }
  } catch (_e) {
    // ignore json parse error
  }
  return GLOBAL_SSH_DEFAULT_CONFIG;
}
