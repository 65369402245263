import React from 'react';
import { TableProps } from '@backstage/core-components';
import { EditCellColumnDef } from '@material-table/core';
import { isEmpty, has, omit, debounce, cloneDeep } from 'lodash';

export class TableStateHandler {
  filters: Record<string, any> = {};
  columns: string[] = [];
  tableRef: any;
  hasTableRefSet: boolean = false;
  updateFilters: any = debounce(filters => {
    this.filters = filters;
  }, 300);

  setTableRef(tableRef: React.RefObject<TableProps>) {
    this.hasTableRefSet = true;
    this.tableRef = tableRef.current;
    this.prepareColumns();
    this.setInitialFilters();
    this.initCustomFilterChangeListener();
  }

  private prepareColumns() {
    this.columns = this.tableRef.dataManager.columns.map((col: EditCellColumnDef) => col.field);
  }

  private setInitialFilters() {
    const initialFilters = this.tableRef.dataManager.columns.reduce(
      (acc: Record<string, any>, cur: EditCellColumnDef) => {
        if (!isEmpty(cur?.tableData?.filterValue)) {
          return { ...acc, [cur.field as string]: cur.tableData.filterValue };
        }
        return acc;
      },
      {},
    );
    this.filters = cloneDeep(initialFilters);
  }

  private initCustomFilterChangeListener() {
    const originalFilterChange = this.tableRef.onFilterChange;

    this.tableRef.onFilterChange = (columnIndex: number, columnValue: any) => {
      originalFilterChange(columnIndex, columnValue);
      const columnKey = this.columns[columnIndex];

      if (isEmpty(columnValue) && has(this.filters, columnKey)) {
        this.updateFilters(omit(this.filters, columnKey));
      } else if (!isEmpty(columnValue)) {
        this.updateFilters({ ...this.filters, [columnKey]: columnValue });
      }
    };
  }

  clearAllFilters() {
    this.tableRef?.dataManager?.columns?.forEach((item: any) => {
      this.tableRef.onFilterChange(item.tableData.id, '');
    });
    this.updateFilters({});
  }

  hasActiveFilters() {
    return Object.keys(this.filters).length > 0;
  }

  getFilters() {
    return this.filters;
  }
}
