import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import { Button, CircularProgress } from '@material-ui/core';
import RebootCircleIcon from '@material-ui/icons/RemoveCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {
  apiPermission,
  buttonPropsFromPermission,
  errorFromWrappedError,
  k8sNodeApiRef,
  usePermission,
  useSnackbarHelper,
  useTimeNode,
} from '@netinsight/management-app-common-react';
import { nodeApi as contract } from '@netinsight/management-app-common-api';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';

export const TimeNodeReboot = ({ nodeId }: { nodeId: string }) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const { snackbar } = useSnackbarHelper();

  const { data: nodeState, isLoading: isLoadingNodeState } = useTimeNode(nodeId, { refreshInterval: 15_000 });
  const { isLoading: isLoadingPermission, ...permission } = usePermission(apiPermission(contract.rebootNode));

  const [open, setOpen] = React.useState<boolean>(false);
  const [rebooting, setRebooting] = React.useState<boolean>(nodeState?.spec.lifecycleState === 'Rebooting');
  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    if (rebooting) return;
    setOpen(false);
  };

  if (isLoadingNodeState || isLoadingPermission) {
    return (
      <InfoCard title="Reboot">
        <Progress />
      </InfoCard>
    );
  }

  const nodeName = nodeState?.spec.name ?? nodeId;
  const rebootNode = () => {
    setRebooting(true);
    nodeApi
      .rebootNode({
        params: { nodeId },
      })
      .then(result => {
        if (result.status !== StatusCodes.OK) {
          throw errorFromWrappedError(result.status, result.body);
        }
        snackbar.info(`Node '${nodeName}' is being rebooted`);
        closeDialog();
      })
      .catch(err => {
        snackbar.error(`Reboot of node '${nodeName}' error: ${err.message}`);
        setRebooting(false);
      });
  };
  const namedState = nodeState?.spec.lifecycleState;
  const disableButton = namedState && namedState !== 'Up';

  return (
    <InfoCard title="Reboot" subheader="Schedule a reboot of the time node">
      <Button
        variant="contained"
        size="large"
        color="secondary"
        onClick={openDialog}
        startIcon={rebooting ? <CircularProgress color="inherit" size={20} /> : <RebootCircleIcon />}
        data-testid="btn-begin-reboot-node"
        {...buttonPropsFromPermission(permission)}
        disabled={!permission.isAllowed || disableButton || rebooting}
      >
        {rebooting ? 'Reboot requested' : 'Reboot'}
      </Button>
      <Dialog open={open} onClose={closeDialog} data-testid="dialog-reboot-node">
        <DialogTitle>Reboot {nodeName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The node will be rebooted and be unavailable for a period. Are you sure you want to reboot the node '
            {nodeName}'?
          </DialogContentText>
          {rebooting && <Progress />}
        </DialogContent>
        <DialogActions>
          <Button disabled={rebooting} onClick={closeDialog} data-testid="btn-cancel-reboot-node">
            Cancel
          </Button>
          <Button
            disabled={rebooting}
            variant="contained"
            color="secondary"
            onClick={rebootNode}
            data-testid="btn-confirm-reboot-node"
          >
            Reboot
          </Button>
        </DialogActions>
      </Dialog>
    </InfoCard>
  );
};
