import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { getScalarResultFromPromise } from '@netinsight/management-app-common-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';

const CacheKey = 'useGnssPositionMetrics';

export const useGnssPositionMetrics = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  return useSwr(
    [nodeId, CacheKey],
    async ([nodeIdParam]) => {
      const [latResult, lonResult, altMslResult, eastDevResult, northDevResult, upDevResult] = await Promise.allSettled(
        [
          metricsApi.instantQuery({ query: `neti_gnss_fix_latitude{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_gnss_fix_longitude{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_gnss_fix_altMSL{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_gnss_fix_eastdev{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_gnss_fix_northdev{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_gnss_fix_updev{nodeid="${nodeIdParam}"}` }),
        ],
      );

      return {
        ...getScalarResultFromPromise(val => ({ lat: parseFloat(val) }))(latResult),
        ...getScalarResultFromPromise(val => ({ lon: parseFloat(val) }))(lonResult),
        ...getScalarResultFromPromise(val => ({ altMsl: parseFloat(val) }))(altMslResult),
        ...getScalarResultFromPromise(val => ({ eastDev: parseFloat(val) }))(eastDevResult),
        ...getScalarResultFromPromise(val => ({ northDev: parseFloat(val) }))(northDevResult),
        ...getScalarResultFromPromise(val => ({ upDev: parseFloat(val) }))(upDevResult),
      };
    },
    swrConfig,
  );
};

export type UseGnssPositionMetricsResultData = NonNullable<ReturnType<typeof useGnssPositionMetrics>['data']>;
