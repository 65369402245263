import z from 'zod';
import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import { Configuration, ConfigurationSchema as NodeManagerConfigSchema } from '@netinsight/node-manager-schema';
import { ServiceConfigSchema, WrappedErrorSchema } from './types';
import { GlobalLinkOptionSchema, TimeTransferSpecSchema, GlobalLinkOptions } from '@netinsight/crds-timetransfer';
import { TimeNodeSpecSchema } from '@netinsight/crds';
import { systemRead, systemUpdate } from '@netinsight/zyntai-policy';

export type { TimeNodeSpec } from '@netinsight/crds';
export { NodeManagerConfigSchema };
export type NodeManagerConfig = Configuration;

const c = initContract();

export const LogRedactLevelSchema = z.union([
  z.literal('none').describe('None'),
  z.literal('mark').describe('Mark'),
  z.literal('full').describe('Full'),
]);

export const GlobalLoggingConfigSchema = z.object({
  logRedactLevel: LogRedactLevelSchema.optional(),
});

// TODO: separate SSH keys schema in @netinsight/node-manager-schema and use it here and scheduler service
export const SSHKeysSchema = NodeManagerConfigSchema.shape.system.unwrap().shape.sshKeys.unwrap();
export const SSHKeysConfigSchema = z.object({
  sshKeys: SSHKeysSchema,
});
// define GlobalSSHConfigSchema as an extension in case we need to add more fields in the future
export const GlobalSSHConfigSchema = SSHKeysConfigSchema.extend({});

export const GlobalNodeOptionSchema = z.object({
  timeErrorThresholdNS: z.number().default(1100).describe('Threshold for triggering node time error alert (ns)'),
});

export const GlobalSettingsSchema = z.object({
  logging: GlobalLoggingConfigSchema.optional(),
  sshKeys: SSHKeysSchema.optional(),
  linkOptions: GlobalLinkOptionSchema.optional(),
  nodeOptions: GlobalNodeOptionSchema.optional(),
});

export type GlobalSettings = z.infer<typeof GlobalSettingsSchema>;
export type SSHKeysConfig = z.infer<typeof SSHKeysConfigSchema>;
export type GlobalSSHConfig = z.infer<typeof GlobalSSHConfigSchema>;
export const GLOBAL_SSH_DEFAULT_CONFIG: GlobalSSHConfig = { sshKeys: [] };
export const GLOBAL_SSH_CONFIG_NAME = 'global-ssh';
export const GLOBAL_SSH_CONFIG_KEY = 'config';

export const GLOBAL_LINK_OPTIONS_DEFAULT: GlobalLinkOptions = {};
export const GLOBAL_LINK_OPTIONS_NAME = 'global-link-options';
export const GLOBAL_LINK_OPTIONS_KEY = 'config';

export const GLOBAL_SETTINGS_DEFAULT = {
  sshKeys: GLOBAL_SSH_DEFAULT_CONFIG.sshKeys,
  linkOptions: GLOBAL_LINK_OPTIONS_DEFAULT,
  logging: {
    logRedactLevel: 'mark',
  },
  nodeOptions: {
    timeErrorThresholdNS: GlobalNodeOptionSchema.shape.timeErrorThresholdNS._def.defaultValue(),
  },
} satisfies GlobalSettings;
export const GLOBAL_SETTINGS_NAME = 'global-settings';
export const GLOBAL_SETTINGS_KEY = 'config';

export const PtpTransmitterClientsRefreshSchema = z.object({
  index: z.number(),
});

export const configApi = c.router({
  getNodeManagerConfig: {
    method: 'GET',
    path: '/nodes/:nodeId/node-manager/config',
    summary: 'Get node network config',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: NodeManagerConfigSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },
  setNodeManagerConfig: {
    method: 'PUT',
    path: '/nodes/:nodeId/node-manager/config',
    summary: 'Set node network config',
    body: NodeManagerConfigSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemUpdate },
  },

  getNodeTimeTransferConfig: {
    method: 'GET',
    path: '/nodes/:nodeId/timetransfer/config',
    summary: 'Get node TimeTransfer config',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: TimeTransferSpecSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },
  setNodeTimeTransferConfig: {
    method: 'PUT',
    path: '/nodes/:nodeId/timetransfer/config',
    summary: 'Set node TimeTransfer config',
    body: TimeTransferSpecSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemUpdate },
  },
  refreshPtpTransmitterClients: {
    method: 'POST',
    path: '/nodes/:nodeId/timetransfer/ptptransmitterclients',
    summary: 'Refresh PTP transmitter clients list',
    body: PtpTransmitterClientsRefreshSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },
  getNodeTimeNodeConfig: {
    method: 'GET',
    path: '/nodes/:nodeId/timenode/config',
    summary: 'Get time node config',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: TimeNodeSpecSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },

  setNodeTimeNodeConfig: {
    method: 'PUT',
    path: '/nodes/:nodeId/timenode/config',
    summary: 'Set time node config',
    body: TimeNodeSpecSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemUpdate },
  },

  getGlobalSSHConfig: {
    method: 'GET',
    path: '/cluster/ssh',
    summary: 'Get global SSH config for nodes',
    responses: {
      [StatusCodes.OK]: GlobalSSHConfigSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },

  setGlobalSSHConfig: {
    method: 'PUT',
    path: '/cluster/ssh',
    summary: 'Set global SSH config for nodes',
    body: GlobalSSHConfigSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemUpdate },
  },

  getGlobalLinkOptions: {
    method: 'GET',
    path: '/cluster/link-options',
    summary: 'Get global options for all links',
    responses: {
      [StatusCodes.OK]: GlobalLinkOptionSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },

  setGlobalLinkOptions: {
    method: 'PUT',
    path: '/cluster/link-options',
    summary: 'Set global options for all links',
    body: GlobalLinkOptionSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemUpdate },
  },

  getGlobalSettings: {
    method: 'GET',
    path: '/cluster/settings',
    summary: 'Get global settings',
    responses: {
      [StatusCodes.OK]: GlobalSettingsSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead },
  },

  setGlobalSettings: {
    method: 'PUT',
    path: '/cluster/settings',
    summary: 'Set global settings',
    body: GlobalSettingsSchema,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemUpdate },
  },
});
