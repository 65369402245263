import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { z } from 'zod';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  CheckboxField,
  TextField,
  useFormStyles,
  useTimeTransferConfig,
  useTextFieldController,
  useTimeTransferConfigUpdate,
  usePermission,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { PPSOutConfigSchema, TIMETRANSFER_DEFAULT_CONFIG } from '@netinsight/crds-timetransfer';
import { PpsOutputSource } from '@netinsight/syncd-schema';
import { PPSOutSquelchingOptionSelector } from './PPSOutSquelchingOptionSelector';

export type PPSOutFormValues = z.infer<typeof PPSOutConfigSchema>;

export const TimeNodePPSOutTab = ({ nodeId }: { nodeId: string }) => {
  const { data: config, isLoading, error } = useTimeTransferConfig(nodeId);
  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const styles = useFormStyles();
  const initialFormValues = useMemo(() => config?.ppsOut ?? TIMETRANSFER_DEFAULT_CONFIG.ppsOut, [config]);
  const prevFormValues = usePrevious(initialFormValues);

  const formProps = useForm<PPSOutFormValues>({
    mode: 'onChange',
    defaultValues: initialFormValues,
    resolver: zodResolver(PPSOutConfigSchema),
  });
  const { control, reset, handleSubmit, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });

  useEffect(() => {
    if (!F.equals(prevFormValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevFormValues, reset]);

  const { field: delayNsFieldProps } = useTextFieldController({
    control,
    name: 'delayNs',
    label: 'Delay compensation (ns)',
    placeholder: '0',
    schema: PPSOutConfigSchema.shape.delayNs,
  });

  const { field: pulseLengthNsFieldProps } = useTextFieldController({
    control,
    name: 'pulseLengthNs',
    label: 'Pulse length (ns)',
    placeholder: '20000',
    schema: PPSOutConfigSchema.shape.pulseLengthNs,
  });

  const sourceCheckboxProps = useController({
    control,
    name: 'source',
  });

  const handleResult = useCallback(
    (newConfig: PPSOutFormValues) =>
      updateConfig(existingConfig => ({
        ...existingConfig,
        ppsOut: newConfig,
      })),
    [updateConfig],
  );

  if (isLoading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="PPS-out" titleTypographyProps={{ component: 'h5' }}>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(handleResult)} className={styles.formContainer}>
          <CheckboxField
            label="Enable"
            fieldProps={sourceCheckboxProps}
            fromValue={value => value === PpsOutputSource.TenMhzOsc}
            toValue={checked => (checked ? PpsOutputSource.TenMhzOsc : PpsOutputSource.LogicZero)}
          />
          <TextField {...delayNsFieldProps} />
          <TextField {...pulseLengthNsFieldProps} />
          <PPSOutSquelchingOptionSelector />
          <Box>
            <Button {...buttonProps} data-testid="btn-submit" />
          </Box>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
