import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { TableColumn, TableProps } from '@backstage/core-components';
import { Filter, Options } from '@material-table/core';
import { F, G } from '@mobily/ts-belt';

export type StoredTableState = {
  columnHiddenMap?: Record<string, boolean>;
  columnSortOrder?: { index: number; direction: 'asc' | 'desc' };
  columnFilters?: Record<string, any>;
  searchText?: string;
};

export const useTableState = <T extends object = any>(
  columns: TableColumn<T>[],
  options: Options<T>,
  key: string,
): Omit<TableProps<T>, 'data'> => {
  const [initialStates, setInitialStates] = useLocalStorage<StoredTableState>(`table-states:${key}`);
  const [columnHiddenMap, setColumnHiddenMap] = useState<StoredTableState['columnHiddenMap']>(
    () => initialStates?.columnHiddenMap ?? {},
  );
  const [columnFilters, setColumnFilters] = useState<StoredTableState['columnFilters']>(
    () => initialStates?.columnFilters ?? {},
  );
  const [searchText, setSearchText] = useState<StoredTableState['searchText']>(() => initialStates?.searchText);
  const [columnSortOrder, setColumnSortOrder] = useState<StoredTableState['columnSortOrder']>(
    () => initialStates?.columnSortOrder,
  );

  useEffect(() => {
    setInitialStates({
      columnHiddenMap,
      columnSortOrder,
      columnFilters,
      searchText,
    });
  }, [columnHiddenMap, columnFilters, columnSortOrder, searchText, setInitialStates]);

  const handleChangeColumnHidden = useCallback((col: { field?: any }, hidden: boolean) => {
    setColumnHiddenMap(map => ({ ...map, [col.field as string]: hidden }));
  }, []);
  const handleSearchChange = useMemo(
    () =>
      F.throttle((text: string) => {
        setSearchText(text);
      }, 250),
    [],
  );
  const handleOrderChange = useCallback((index: number, direction: 'asc' | 'desc') => {
    setColumnSortOrder({ index, direction });
  }, []);

  const handleFilterChange = useCallback((filters: Filter<any>[]) => {
    setColumnFilters(Object.fromEntries(filters.map(({ column, value }) => [column.field, value])));
  }, []);

  const statefulColumns = useMemo(() => {
    const hasStoredSortOrder =
      G.isNotNullable(columnSortOrder?.index) &&
      (columnSortOrder?.index ?? 0) < columns.length &&
      G.isNotNullable(columnSortOrder?.direction);
    return columns.map((c, cIdx) => ({
      ...c,
      hidden: columnHiddenMap?.[c.field as string] ?? c.hidden,
      // eslint-disable-next-line no-nested-ternary
      defaultSort: hasStoredSortOrder
        ? columnSortOrder?.index === cIdx
          ? columnSortOrder?.direction
          : undefined
        : c.defaultSort,
      defaultFilter: G.isNotNullable(columnFilters?.[c.field as string])
        ? columnFilters?.[c.field as string]
        : undefined,
    }));
  }, [columns, columnHiddenMap, columnSortOrder, columnFilters]);

  const statefulOptions = useMemo(() => {
    return {
      ...options,
      searchText: options.search === true ? searchText : undefined,
    };
  }, [options, searchText]);

  return {
    onChangeColumnHidden: handleChangeColumnHidden,
    onSearchChange: handleSearchChange,
    onOrderChange: handleOrderChange,
    onFilterChange: handleFilterChange as any,
    columns: statefulColumns as any as TableColumn<T>[],
    options: statefulOptions as any,
  };
};
