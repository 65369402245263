import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@material-ui/core';
import { G } from '@mobily/ts-belt';
import { useFormStyles, InputTooltip, ValueOf } from '@netinsight/management-app-common-react';
import { type PPSOutFormValues } from './TimeNodePPSOutTab';

const PPSOutSquelchUIOptions = {
  Squelch: 'squelch',
  NeverSquelch: 'never-squelch',
  ExternalPpsHoldover: 'external-pps-holdover',
} as const;
type PPSOutSquelchUIOption = ValueOf<typeof PPSOutSquelchUIOptions>;

const config2Option = (
  input: Pick<PPSOutFormValues, 'enableSquelch' | 'enableExternalPPSHoldover'>,
): PPSOutSquelchUIOption => {
  if (input.enableExternalPPSHoldover) {
    return PPSOutSquelchUIOptions.ExternalPpsHoldover;
  }
  return input.enableSquelch ? PPSOutSquelchUIOptions.Squelch : PPSOutSquelchUIOptions.NeverSquelch;
};
const option2Config = (
  input: PPSOutSquelchUIOption,
): Pick<PPSOutFormValues, 'enableSquelch' | 'enableExternalPPSHoldover'> => {
  return {
    enableExternalPPSHoldover: input === PPSOutSquelchUIOptions.ExternalPpsHoldover,
    enableSquelch: input === PPSOutSquelchUIOptions.Squelch || input === PPSOutSquelchUIOptions.ExternalPpsHoldover,
  };
};

export const PPSOutSquelchingOptionSelector = () => {
  const styles = useFormStyles();
  const { getValues, setValue } = useFormContext<PPSOutFormValues>();

  const [squelchOption, setSquelchOption] = useState<PPSOutSquelchUIOption>(
    config2Option({
      enableSquelch: getValues().enableSquelch === true || G.isNullable(getValues().enableSquelch),
      enableExternalPPSHoldover: getValues().enableExternalPPSHoldover === true,
    }),
  );

  const handleSquelchChange = useCallback(
    (evt: any) => {
      const val = evt.target.value as PPSOutSquelchUIOption;
      const cfg = option2Config(val);
      setSquelchOption(val);
      setValue('enableSquelch', cfg.enableSquelch);
      setValue('enableExternalPPSHoldover', cfg.enableExternalPPSHoldover);
    },
    [setValue],
  );
  return (
    <Paper variant="elevation" component="fieldset" className={styles.formSection}>
      <Typography component="legend" variant="subtitle1">
        Squelching{' '}
        <InputTooltip text="Squelching means suppressing the output, which is useful for only sending output pulses when the node time is good enough." />
      </Typography>
      <RadioGroup aria-label="Squelching" onChange={handleSquelchChange} value={squelchOption}>
        <FormControlLabel
          value={PPSOutSquelchUIOptions.Squelch}
          control={<Radio color="primary" />}
          label={
            <>
              Squelch when node time is unstable{' '}
              <InputTooltip text="This is the default and should be used in almost all setups. Output pulses are only sent when the node time is stable." />{' '}
            </>
          }
        />
        <FormControlLabel
          value={PPSOutSquelchUIOptions.NeverSquelch}
          control={<Radio color="primary" />}
          label={
            <>
              Never squelch{' '}
              <InputTooltip text="This is used for setups where the receiver has poor holdover performance and it can be tolerated that the pulses are possibly bad during start or transients. This is not recommended." />
            </>
          }
        />
        <FormControlLabel
          value={PPSOutSquelchUIOptions.ExternalPpsHoldover}
          control={<Radio color="primary" />}
          label={
            <>
              External PPS holdover{' '}
              <InputTooltip text="This is used to support setups where there is an external device used to improve the holdover performance, such as an atomic clock with PPS in and out. The external device will receive PPS pulses from zyntai PPS-out and adapt to the phase and frequency of the zyntai node time. The external device will send PPS pulses to zyntai PPS-in. In case the node loses all other sync sources and PPS in is selected, PPS out will be squelched in order not to create a sync loop. PPS out will also be squelched when the node time is not stable, such as during startup." />
            </>
          }
        />
      </RadioGroup>
    </Paper>
  );
};
