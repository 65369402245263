import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { G } from '@mobily/ts-belt';
import {
  useAlertGroups,
  useAlertMetadataUpdate,
  AlarmsTable,
  AlertInfo,
  useAlertMetadata,
  usePermission,
} from '@netinsight/management-app-common-react';
import {
  getSyncRegionsLookupForTable,
  useSyncRegionByNodeIdLookup,
  useSyncRegions,
} from '@netinsight/plugin-sync-region-ui';
import { ProfileInfo, identityApiRef, useApi } from '@backstage/core-plugin-api';

export const AlarmsTableCard: FunctionComponent<{ nodeId?: string; enableSyncRegionsFiltering?: boolean }> = ({
  nodeId,
  enableSyncRegionsFiltering,
}) => {
  const {
    isLoading,
    error,
    data: alertGroups,
  } = useAlertGroups(G.isNotNullable(nodeId) ? { labels: { nodeid: nodeId } } : undefined);

  const { data: syncRegions } = useSyncRegions();
  const lookupSyncRegion = useSyncRegionByNodeIdLookup(syncRegions);
  const identityApi = useApi(identityApiRef);

  const { data: alertMetadataList, isLoading: isMetadataLoading } = useAlertMetadata();
  const { trigger: updateAlertMetadata, permission: updatePermission } = useAlertMetadataUpdate();
  const { isLoading: isLoadingPermission, ...canUpdatePerm } = usePermission(updatePermission);

  const syncRegionsLookup = useMemo(
    () => (enableSyncRegionsFiltering ? getSyncRegionsLookupForTable(syncRegions ?? []) : undefined),
    [syncRegions, enableSyncRegionsFiltering],
  );
  const alerts: AlertInfo[] = useMemo(() => {
    return (
      alertGroups?.flatMap(group =>
        group.alerts.map(alert => {
          const metadata = alertMetadataList?.find(
            obj => obj.labels.fingerprint === alert.fingerprint && obj.labels.startsAt === alert.startsAt,
          );
          const acknowledged = metadata?.acknowledged ?? false;
          return {
            group: alert.annotations.summary ?? group.labels.alertname,
            type: alert.annotations.typeName ?? alert.labels.type,
            details: alert.annotations.description ?? alert.annotations.summary,
            severity: alert.labels.severity,
            node: alert.labels.nodeid,
            object: alert.annotations.object ?? '',
            nodeName: alert.labels.node,
            syncRegion: lookupSyncRegion(alert.labels.nodeid),
            service: alert.labels.service_name ?? alert.labels.job,
            raised: alert.startsAt,
            fingerprint: alert.fingerprint,
            metadata,
            acknowledged,
          };
        }),
      ) ?? []
    );
  }, [alertGroups, lookupSyncRegion, alertMetadataList]);

  const [profileInfo, setProfileInfo] = useState<ProfileInfo>();
  useEffect(() => {
    identityApi
      .getProfileInfo()
      .then(profile => {
        setProfileInfo(profile);
      })
      .catch(_e => {}); // ingore the unlikely error of not being logged in
  }, [identityApi]);

  if (isLoading || isMetadataLoading || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  return (
    <AlarmsTable
      nodeId={nodeId}
      alerts={alerts}
      metadataUpdate={updateAlertMetadata}
      permission={canUpdatePerm}
      profileInfo={profileInfo}
      syncRegionsLookup={syncRegionsLookup}
      stateStorageKey={typeof nodeId !== 'undefined' ? 'node-alarms-table' : 'alarms-table'}
    />
  );
};
