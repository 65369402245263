import useSwr, { SWRConfiguration } from 'swr';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sGroupCalibrationApiRef } from '@netinsight/management-app-common-react';
import { GroupCalibrationSpec } from '@netinsight/group-calibration-api';

export const Key = 'useGroupCalibrationSpec';

export const useGroupCalibrationSpec = (swrConfig?: SWRConfiguration) => {
  const groupCalibrationApi = useApi(k8sGroupCalibrationApiRef);

  const get = useCallback(async () => {
    const response = await groupCalibrationApi.getGroupCalibrationSpec();
    if (response.status !== 200) {
      throw errorFromWrappedError(response.status, response.body);
    }
    return response.body!;
  }, [groupCalibrationApi]);
  return useSwr<GroupCalibrationSpec>([Key], get, swrConfig);
};
