import StatusCodes from 'http-status-codes';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import {
  k8sConfigApiRef,
  useSnackbarHelper,
  isNotNullableButEmpty,
  errorFromWrappedError,
  GlobalSettingsCacheKey,
  apiPermission,
} from '@netinsight/management-app-common-react';
import { GlobalSettings, configApi as contract } from '@netinsight/management-app-common-api';

export const useGlobalSettingsUpdate = () => {
  const configApi = useApi(k8sConfigApiRef);

  const { snackbar } = useSnackbarHelper();

  const update = async (_url: string, { arg: newOptions }: { arg: GlobalSettings }) => {
    // avoid an error that occurs when the object is not null but empty
    if (isNotNullableButEmpty(newOptions?.linkOptions?.dynamicLinkChangeThresholdParams?.bad)) {
      delete newOptions!.linkOptions!.dynamicLinkChangeThresholdParams!.bad;
    }
    if (isNotNullableButEmpty(newOptions?.linkOptions?.dynamicLinkChangeThresholdParams?.good)) {
      delete newOptions!.linkOptions!.dynamicLinkChangeThresholdParams!.good;
    }
    if (isNotNullableButEmpty(newOptions?.linkOptions?.dynamicLinkChangeThresholdParams)) {
      delete newOptions!.linkOptions!.dynamicLinkChangeThresholdParams;
    }
    if (isNotNullableButEmpty(newOptions?.linkOptions)) {
      delete newOptions!.linkOptions;
    }
    if (isNotNullableButEmpty(newOptions?.logging)) {
      delete newOptions!.logging;
    }
    if (isNotNullableButEmpty(newOptions?.nodeOptions)) {
      delete newOptions!.nodeOptions;
    }

    const response = await configApi.setGlobalSettings({ body: newOptions });

    if (response.status !== StatusCodes.OK) {
      throw errorFromWrappedError(response.status, response.body);
    }
  };

  const mutations = useSwrMutation(GlobalSettingsCacheKey, update, {
    throwOnError: false,
    onSuccess: () => {
      snackbar.notifySuccess('Update');
    },
    onError: error => {
      snackbar.notifyError('Update', error.response, null);
    },
  });

  return { ...mutations, permission: apiPermission(contract.setGlobalSettings) };
};
