/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { z } from 'zod';
import { InfoCard } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  NetiHeadingTypographyProps,
  TextField,
  usePermission,
  useFormStyles,
  useGlobalSettings,
  useTextFieldController,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { useGlobalSettingsUpdate } from '../../hooks';
import { GlobalNodeOptionSchema, GlobalSettings, GlobalSettingsSchema } from '@netinsight/management-app-common-api';

type GlobalNodeOptionFormValues = Pick<GlobalSettings, 'nodeOptions'>;

export const GlobalAlertConfigForm = () => {
  const formStyles = useFormStyles();
  const { data = {}, isLoading } = useGlobalSettings();
  const initialValues = useMemo(() => ({ nodeOptions: data.nodeOptions }), [data]);
  const { trigger: update, permission: updatePermission } = useGlobalSettingsUpdate();
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);

  const formProps = useForm<GlobalNodeOptionFormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        nodeOptions: GlobalSettingsSchema.shape.nodeOptions,
      }),
    ),
  });
  const handleSubmitCallback = useCallback(
    async (submittedData: GlobalNodeOptionFormValues) => {
      await update({ ...(data ?? {}), ...submittedData });
    },
    [update, data],
  );
  const { handleSubmit, reset, control, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const prevInitialValues = usePrevious(initialValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialValues)) {
      reset(initialValues);
    }
  }, [initialValues, prevInitialValues, reset]);

  const nodeOptionFields = GlobalNodeOptionSchema.shape;

  const { field: thresholdProps } = useTextFieldController({
    control,
    name: 'nodeOptions.timeErrorThresholdNS',
    schema: nodeOptionFields.timeErrorThresholdNS,
    label: 'Threshold (ns)',
    placeholder: nodeOptionFields.timeErrorThresholdNS._def.defaultValue().toString(),
  });

  if (isLoading || isLoadingPermission) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSubmitCallback)} className={formStyles.formContainer}>
        <InfoCard
          title="Global alert settings"
          titleTypographyProps={NetiHeadingTypographyProps.h2}
          cardClassName={formStyles.formContainer}
        >
          <Typography component="h6" variant="h6">
            Node time error
          </Typography>
          <Typography variant="caption">
            Alert when node time error exceeds threshold compared to its configured reference. This is the global
            default setting. The threshold can also be configured per node.
          </Typography>
          <div className={formStyles.formGrid}>
            <TextField {...thresholdProps} />
          </div>
        </InfoCard>
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} data-testid="btn-save-global-alert-settings" />
        </div>
      </form>
    </FormProvider>
  );
};
