import cx from 'classnames';
import prettyBytes from 'pretty-bytes';
import React, { useEffect, useMemo, useRef } from 'react';
import { Link, Table, TableProps, TableColumn } from '@backstage/core-components';
import { Button, makeStyles, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import {
  AlarmSummaryChip,
  DurationFormatter,
  GrafanaLink,
  HeartbeatStatusLabels,
  Meter,
  NodeOnlineStatusIcon,
  SyncActiveStatusIcon,
  TableStateHandler,
  useBackendTableState,
  useGrafanaDashboards,
} from '@netinsight/management-app-common-react';
import { controlStateScale, SYNC_SOURCE_LABELS } from '@netinsight/plugin-sync-inputs-ui';
import { D, G } from '@mobily/ts-belt';
import {
  CONTROL_STATES,
  SYNC_SOURCE_NAMES,
  SyncSourceName,
  TimeNodeQueryResultItem,
} from '@netinsight/management-app-common-api';
import { getSyncRegionsLookupForTable, useSyncRegions } from '@netinsight/plugin-sync-region-ui';
import { useTimeNodesTableQueryCallback } from '../../hooks';

const cellStyle = {
  padding: '8px 4px',
} as const;

const headerStyle = {
  ...cellStyle,
  textTransform: 'uppercase',
} as const;

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  cellIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  warningIcon: {
    fill: theme.palette.warning.main,
  },
  okIcon: {
    fill: theme.palette.success.main,
  },
  errorIcon: {
    fill: theme.palette.error.main,
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  cellNoWrap: {
    flexWrap: 'nowrap',
  },
  controlStateLabel: { whiteSpace: 'nowrap', textTransform: 'capitalize' },
  nodeNameHeaderLabel: { paddingLeft: '12px' },
  nodeNameLabel: { whiteSpace: 'nowrap' },
  grafanaLinkInTable: {
    margin: theme.spacing(1, 2.5, 1, 1),
  },
}));

const ControlStateColumnLookup = {
  [CONTROL_STATES.Holdover]: controlStateScale(CONTROL_STATES.Holdover),
  [CONTROL_STATES.RelTime]: controlStateScale(CONTROL_STATES.RelTime),
  [CONTROL_STATES.AbsTime]: controlStateScale(CONTROL_STATES.AbsTime),
};

export const TimeNodeListTable = () => {
  const styles = useStyles({});
  const { data: syncRegions } = useSyncRegions({ refreshInterval: 0, revalidateOnFocus: false });
  const { data: grafanaDashboards } = useGrafanaDashboards();
  const controlStateIcons = useMemo(
    () => ({
      [CONTROL_STATES.Holdover]: <ErrorOutlineIcon className={cx(styles.cellIcon, styles.errorIcon)} />,
      [CONTROL_STATES.AbsTimeSymmetric]: <ScheduleOutlinedIcon className={cx(styles.cellIcon, styles.warningIcon)} />,
      [CONTROL_STATES.RelTime]: <ScheduleOutlinedIcon className={cx(styles.cellIcon, styles.warningIcon)} />,
      [CONTROL_STATES.AbsTime]: <CheckCircleOutlineIcon className={cx(styles.cellIcon, styles.okIcon)} />,
    }),
    [styles],
  );
  const nodeListTableRef = useRef<TableProps>(null);
  const { current: NodeListTableState } = useRef(new TableStateHandler());

  useEffect(() => {
    if (nodeListTableRef?.current && !NodeListTableState.hasTableRefSet) {
      NodeListTableState.setTableRef(nodeListTableRef);
    }
  }, [NodeListTableState, nodeListTableRef]);

  const columns: TableColumn<TimeNodeQueryResultItem>[] = useMemo(
    () => [
      {
        align: 'left' as const,
        defaultSort: 'asc',
        field: 'name',
        highlight: true,
        render: row => (
          <Link className={styles.nodeNameLabel} to={`info/${row.id}`} data-testid="btn-node-name">
            {row.name}
          </Link>
        ),
        title: <span className={styles.nodeNameHeaderLabel}>Name</span>,
        width: '25rem',
      },
      {
        align: 'left',
        cellStyle,
        defaultSort: 'asc',
        field: 'syncRegion',
        title: 'Sync region',
        width: '8rem',
        lookup: getSyncRegionsLookupForTable(syncRegions ?? []),
      },
      {
        align: 'center',
        field: 'lifecycleStateHeartbeatStatus',
        lookup: HeartbeatStatusLabels,
        render: row => (
          <NodeOnlineStatusIcon
            lifecycleState={row.lifecycleState}
            heartbeatTime={row.heartbeatTime}
            heartbeatStatus={row.heartbeatStatus}
            iconOnly
          />
        ),
        title: 'Online',
        width: '8rem',
        sorting: false,
      },
      {
        align: 'center',
        field: 'alertsCount',
        filtering: false,
        cellStyle,
        render: row =>
          (row.alarmsCount ?? 0) > 0 ? (
            <AlarmSummaryChip
              count={row.alarmsCount ?? 0}
              severity={row.alarmsSeverity}
              url={`/nodes/info/${row.id}/alarms`}
            />
          ) : null,
        title: 'Alarms',
        width: '5rem',
      },
      {
        align: 'center',
        cellStyle,
        field: 'inputs',
        lookup: D.deleteKey(SYNC_SOURCE_LABELS, 'osc'),
        render: row => (
          <>
            {Object.entries(row.inputs).map(([inputName]) => (
              <SyncActiveStatusIcon
                key={inputName}
                label={SYNC_SOURCE_LABELS[inputName as SyncSourceName]}
                showIcon={false}
              />
            ))}
          </>
        ),
        sorting: false,
        title: 'Inputs',
        width: '8rem',
      },
      {
        align: 'center',
        cellStyle,
        field: 'outputs',
        lookup: {
          ptp: 'PTP',
          pps: 'PPS',
        },
        render: row => (
          <>
            {Object.entries(row.outputs).map(([outputName]) => (
              <SyncActiveStatusIcon key={outputName} label={outputName?.toUpperCase()} showIcon={false} />
            ))}
          </>
        ),
        sorting: false,
        title: 'Outputs',
        width: '8rem',
      },
      {
        align: 'left',
        cellStyle,
        field: 'controlState',
        lookup: ControlStateColumnLookup,
        render: row =>
          G.isNotNullable(row.controlState) ? (
            <div className={cx(styles.cellContent, styles.cellNoWrap)}>
              {controlStateIcons[row.controlState] ?? null}
              <Typography component="span" className={styles.controlStateLabel}>
                {controlStateScale(row.controlState)}
              </Typography>
            </div>
          ) : null,
        title: 'Control State',
        width: '12.5rem',
      },
      {
        title: 'PPS Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.ppsIn}`,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        filtering: false,
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.ppsIn])
            ? DurationFormatter.fromSeconds(row.timeErrors[SYNC_SOURCE_NAMES.ppsIn] ?? NaN).toMicroSeconds(3)
            : '',
      },
      {
        title: 'GNSS Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.gnss}`,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        filtering: false,
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.gnss])
            ? DurationFormatter.fromSeconds(row.timeErrors[SYNC_SOURCE_NAMES.gnss] ?? NaN).toMicroSeconds(3)
            : '',
      },
      {
        title: 'PTP1 Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.ptp1}`,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        filtering: false,
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp1])
            ? DurationFormatter.fromSeconds(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp1] ?? NaN).toMicroSeconds(3)
            : '',
      },
      {
        title: 'PTP2 Error (abs)',
        field: `timeErrors.${SYNC_SOURCE_NAMES.ptp2}`,
        type: 'numeric',
        cellStyle,
        width: '10rem',
        filtering: false,
        render: row =>
          G.isNumber(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp2])
            ? DurationFormatter.fromSeconds(row.timeErrors?.[SYNC_SOURCE_NAMES.ptp2] ?? NaN).toMicroSeconds(3)
            : '',
      },
      {
        title: 'CPU',
        field: 'systemCpuTime',
        type: 'numeric',
        cellStyle,
        align: 'center',
        width: '10rem',
        hidden: true,
        filtering: false,
        render: row =>
          G.isNumber(row.systemCpuTime) ? (
            <Meter value={row.systemCpuTime}>{`${(row.systemCpuTime * 100).toFixed(2)}%`}</Meter>
          ) : null,
      },
      {
        title: 'Memory',
        field: 'systemMemoryUsage',
        type: 'numeric',
        cellStyle,
        align: 'center',
        width: '10rem',
        hidden: true,
        filtering: false,
        render: row =>
          G.isNumber(row.systemMemoryUsage) ? (
            <Meter value={Math.min(row.systemMemoryUsage / 1073741824, 1)}>{prettyBytes(row.systemMemoryUsage)}</Meter>
          ) : null,
      },
      {
        title: 'Temp',
        field: 'boardTemp',
        type: 'numeric',
        cellStyle,
        align: 'center',
        width: '10rem',
        hidden: true,
        filtering: false,
        render: row =>
          G.isNumber(row.boardTemp) ? (
            <Meter value={Math.min(row.boardTemp / 90, 1)}>{`${row.boardTemp}°C`}</Meter>
          ) : null,
      },
    ],
    [controlStateIcons, styles, syncRegions],
  );

  const options: TableProps['options'] = useMemo(
    () => ({
      actionsColumnIndex: -1,
      columnsButton: true,
      debounceInterval: 250,
      filtering: true,
      filterCellStyle: cellStyle,
      headerStyle,
      loadingType: 'linear' as const,
      padding: 'dense' as const,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100],
      search: false,
      showEmptyDataSourceMessage: true,
      thirdSortClick: false,
      tableLayout: 'fixed' as const,
    }),
    [],
  );
  const queryCallback = useTimeNodesTableQueryCallback('time-node-list-table');
  const tableStates = useBackendTableState<TimeNodeQueryResultItem>(columns, options, 'time-node-list-table');
  const components: TableProps['components'] = useMemo(
    () => ({
      Actions: () => (
        <>
          <Button
            type="button"
            size="small"
            color="default"
            variant="outlined"
            disabled={!NodeListTableState?.hasActiveFilters()}
            startIcon={<CancelIcon />}
            onClick={() => NodeListTableState?.clearAllFilters()}
            data-testid="btn-clear-nodetable-filters"
          >
            Clear Filters
          </Button>
          <GrafanaLink
            className={styles.grafanaLinkInTable}
            dashboardUrl={grafanaDashboards?.['Zyntai Metrics Overview/time-transfer-multiple-nodes']}
            size="small"
            syncRegionsGetter={() => NodeListTableState?.getFilters()?.syncRegion ?? []}
          />
        </>
      ),
    }),
    [grafanaDashboards, styles, NodeListTableState],
  );
  return (
    <Table<TimeNodeQueryResultItem>
      {...tableStates}
      tableRef={nodeListTableRef}
      title="TimeNodes"
      key="time-node-table"
      data={queryCallback}
      emptyContent={<Typography className={styles.empty}>No timenodes</Typography>}
      components={components}
    />
  );
};
