import {
  GROUP_CALIBRATION_DEFAULT_PRESET,
  GroupCalibrationPreset,
  GroupCalibrationSchedule,
} from '@netinsight/group-calibration-api';
import { cloneDeep } from 'lodash';

export type GroupCalibrationPresetFormValues = GroupCalibrationPreset;
export type SyncRegionItem = { name: string; id: string; nodeIds: string[] };
export type IntervalValue = 'off' | '30m' | '1h' | '2h' | '3h' | '6h' | '12h' | '24h';
export type NodeNameMapWithRegion = {
  nodeId: string;
  regionName: string;
  regionId: string;
  nodeName: string;
};

export const transformSelectedGroupPreset = (
  preset: GroupCalibrationPresetFormValues | null,
): GroupCalibrationPresetFormValues | null => {
  if (!preset) return null;

  return cloneDeep(preset);
};

export const DefaultGroupCalibrationFormValues = {
  ...GROUP_CALIBRATION_DEFAULT_PRESET,
  name: '',
  nodeGroups: ['all'],
} satisfies GroupCalibrationPresetFormValues;

export const NewGroupTemplate = {
  ...DefaultGroupCalibrationFormValues,
} satisfies GroupCalibrationPresetFormValues;

export const IntervalLabels = [
  { id: 'off', label: 'Off' },
  { id: '30m', label: '30 m' },
  { id: '1h', label: '1 h' },
  { id: '2h', label: '2 h' },
  { id: '3h', label: '3 h' },
  { id: '6h', label: '6 h' },
  { id: '12h', label: '12 h' },
  { id: '24h', label: '24 h' },
];

export const IntervalMarks = IntervalLabels.map(({ label }, index) => ({ value: index, label }));

export const spanToSlider = (span: [number, number] | undefined = [0, 24]): [number, number] => span;

export const SpanMarks = Array.from({ length: 25 }).map((_unused, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}));

// prepares the preset to react hook forms used way with some default value checks to improve user experience
export const prepareFormPreset = (preset: GroupCalibrationPreset): GroupCalibrationPresetFormValues => {
  return {
    ...preset,
    schedule: {
      ...preset.schedule,
      commit: preset?.schedule?.commit ?? false,
      interval: preset?.schedule?.interval ?? 'off',
      span: spanToSlider(preset?.schedule?.span),
    },
  };
};

// converts the value from interval to material ui readable label
export const intervalToSlider = (interval: GroupCalibrationSchedule['interval'] | undefined): number =>
  IntervalLabels.findIndex(item => item.id === interval);

// Flattens syncregions by name and merge it in an array where all option is included
export const getNodeGroupOptions = (syncRegions: SyncRegionItem[] = []): string[] => {
  return ['all', ...syncRegions.map(({ name }) => name)];
};

// Returns a flatten array of nodeids , making it easier to manage different include and exclude group options
export const getNodesFromSelectedRegions = (syncRegions: SyncRegionItem[], selectedRegions: string[]): string[] => {
  return syncRegions
    .filter(r => selectedRegions.includes(r.name))
    .reduce((acc, cur) => [...acc, ...(cur.nodeIds as string[])], [] as string[]);
};

export const isIntervalAvailable = (interval: string | null | undefined): boolean => {
  return !!interval && interval !== 'off';
};
