import React, { FunctionComponent } from 'react';
import { LinkButton, LinkButtonProps } from '@backstage/core-components';
import LaunchIcon from '@material-ui/icons/Launch';
import { G } from '@mobily/ts-belt';
import { makeStyles } from '@material-ui/core';
import GrafanaLogo from './grafana-icon.svg';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  linkContainer: {
    alignSelf: 'center',
  },
  grafanaIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

const getSearchParams = (
  syncRegions: string[] = [],
  nodeIds: string[] = [],
  linkIds: string[] = [],
  containers: string[] = [],
) => {
  return new URLSearchParams([
    ...syncRegions.map(syncRegion => ['var-region', syncRegion]),
    ...nodeIds.map(nodeId => ['var-node', nodeId]),
    ...linkIds.map(linkId => ['var-link_ids', linkId]),
    ...containers.map(container => ['var-container', container]),
  ]);
};

const getDashboardUrl = (dashboardUrl: string, params: URLSearchParams) => {
  return `${dashboardUrl}${params.size > 0 ? '?' : ''}${params.toString()}`;
};

export const GrafanaLink: FunctionComponent<
  Omit<LinkButtonProps, 'to'> & {
    dashboardUrl?: string;
    syncRegions?: string[];
    nodeIds?: string[];
    containers?: string[];
    linkIds?: string[];
    label?: string;
    syncRegionsGetter?: () => string[];
  }
> = ({
  dashboardUrl,
  label = 'Metrics',
  syncRegions = [],
  nodeIds = [],
  linkIds = [],
  containers = [],
  className,
  syncRegionsGetter,
  ...props
}) => {
  const styles = useStyles();
  const params = getSearchParams(syncRegionsGetter ? syncRegionsGetter() : syncRegions, nodeIds, linkIds, containers);
  return G.isNotNullable(dashboardUrl) ? (
    <LinkButton
      className={classNames(styles.linkContainer, className)}
      startIcon={<img alt="Grafana logo" src={GrafanaLogo} className={styles.grafanaIcon} aria-hidden />}
      endIcon={<LaunchIcon />}
      variant="outlined"
      color="default"
      size="medium"
      to={getDashboardUrl(dashboardUrl, params)}
      {...props}
    >
      {label}
    </LinkButton>
  ) : null;
};
