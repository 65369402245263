import { useCallback, useMemo } from 'react';
import { TableColumn, TableProps } from '@backstage/core-components';
import { Options } from '@material-table/core';
import { G } from '@mobily/ts-belt';
import { StoredTableState } from './useTableState';

export const useTableLocalStorage = (key: string) => {
  const getValue = (): StoredTableState => {
    const value = localStorage.getItem(`table-states:${key}`);
    return value ? JSON.parse(value) : {};
  };

  const setFieldValue = <K extends keyof StoredTableState>(field: K, value: StoredTableState[K]) => {
    const valueToSet = G.isNotNullable(value) ? value : undefined;
    const currentValue: StoredTableState = getValue();
    const newValue: StoredTableState = { ...currentValue, [field]: valueToSet };
    localStorage.setItem(`table-states:${key}`, JSON.stringify(newValue));
  };

  return {
    getValue,
    setFieldValue,
  };
};

export const useBackendTableState = <T extends object = any>(
  columns: TableColumn<T>[],
  options: Options<T>,
  key: string,
): Omit<TableProps<T>, 'data'> => {
  const { getValue, setFieldValue } = useTableLocalStorage(key);

  const handleChangeColumnHidden = useCallback(
    (col: { field?: any }, hidden: boolean) => {
      setFieldValue('columnHiddenMap', { ...(getValue()?.columnHiddenMap ?? {}), [col.field as string]: hidden });
    },
    [setFieldValue, getValue],
  );

  const handleOrderChange = useCallback(
    (index: number, direction: 'asc' | 'desc') => {
      setFieldValue('columnSortOrder', { index, direction });
    },
    [setFieldValue],
  );

  const statefulColumns = useMemo(() => {
    const { columnSortOrder, columnFilters, columnHiddenMap } = getValue();

    const isValidSortOrder =
      columnSortOrder &&
      G.isNotNullable(columnSortOrder.index) &&
      columnSortOrder.index < columns.length &&
      G.isNotNullable(columnSortOrder.direction);

    return columns.map((column, index) => ({
      ...column,
      hidden: columnHiddenMap?.[column.field as string] ?? column.hidden,
      defaultSort: isValidSortOrder && columnSortOrder.index === index ? columnSortOrder.direction : column.defaultSort,
      defaultFilter:
        G.isNotNullable(columnFilters?.[column.field as string]) && columnFilters?.[column.field as string],
    }));
  }, [getValue, columns]);

  return {
    onChangeColumnHidden: handleChangeColumnHidden,
    onOrderChange: handleOrderChange,
    columns: statefulColumns as any as TableColumn<T>[],
    options: options as any,
  };
};
