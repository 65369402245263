import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { TimeNodeListPage } from './components/TimeNodeListPage/TimeNodeListPage';
import { TimeNetworkPage } from './components/TimeNetworkPage/TimeNetworkPage';
import { TimeNodePage } from './components/TimeNodePage/TimeNodePage';
import { Root } from './components/Root';
import { apis, idpAuthApiRef, idpReadonlyAuthApiRef, localAuthApiRef } from './apis';
import { SnackbarProvider } from 'notistack';
import { SettingsPage } from './components/Settings/SettingsPage';
import { AlarmsPage } from './components/AlarmsPage/AlarmsPage';
import { NetiDarkAppTheme, NetiLightAppTheme } from './theme';
import { LinkEndpointsDrawer } from '@netinsight/plugin-time-transfer-ui';
import { SystemsPage } from './components/SystemsPage';
import { LinkDetailPage } from './components/LinkDetailPage/LinkDetailPage';
import { NotFoundErrorPage } from './components/NotFound';
import { FeatureFlags } from '@netinsight/management-app-common-react';

const app = createApp({
  apis,
  themes: [NetiLightAppTheme, NetiDarkAppTheme],
  components: {
    NotFoundErrorPage: NotFoundErrorPage,
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      return (
        <SignInPage
          {...props}
          align="center"
          providers={[
            ...(process.env.NODE_ENV === 'development' ? ['guest' as const] : []),
            {
              id: 'idp',
              title: 'External Identity Provider',
              message: 'Sign In using third-party identity provider',
              apiRef: idpAuthApiRef,
            },
            ...(configApi.has('auth.providers.idp-readonly')
              ? [
                  {
                    id: 'idp-readonly',
                    title: 'Read-Only access',
                    message: 'Sign In using third-party identity provider for read-only access',
                    apiRef: idpReadonlyAuthApiRef,
                  },
                ]
              : []),
            {
              id: 'local',
              title: 'Static Credentials',
              message: 'Sign In using static credentials',
              apiRef: localAuthApiRef,
            },
          ]}
        />
      );
    },
  },
  featureFlags: [
    {
      name: FeatureFlags.ShowAdvancedSettings,
      description: 'Show advanced settings',
      pluginId: '',
    },
    {
      name: FeatureFlags.ShowExperimentalFeatures,
      description: 'Show Experimental Features',
      pluginId: '',
    },
    { name: FeatureFlags.EnableDebugMode, description: 'Enable debug mode', pluginId: '' },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="nodes" />} />
    <Route path="/alarms" element={<AlarmsPage />} />
    <Route path="/nodes" element={<TimeNodeListPage />} />
    <Route path="/nodes/globalsettings" element={<Navigate to="/system/global-defaults/ssh-keys" />} />
    <Route path="/nodes/info/:node" element={<TimeNodePage />} />
    <Route path="/network" element={<TimeNetworkPage />}>
      <Routes>
        <Route path="/new-link" element={<LinkEndpointsDrawer parentPath="/network" />} />
      </Routes>
    </Route>
    <Route path="/network/links/:linkId" element={<LinkDetailPage />} />
    <Route path="/network/link-options" element={<Navigate to="/system/global-defaults/link-options" />} />
    <Route path="/system" element={<SystemsPage />} />
    <Route path="/settings" element={<SettingsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <SnackbarProvider
    maxSnack={5}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </SnackbarProvider>,
);
